@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
  /* padding: 3rem; */
  background-color: rgba(51, 51, 51, 1);
  overflow-x: hidden;
}
@media only screen and (max-width: 56.25em) {
  body {
    padding: 0;
  }
}

::selection {
  background-color: #55c57a;
  color: #fff;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: #777;
}

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  margin-bottom: 6rem;
}
.heading-primary--main {
  display: block;
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: 3.5rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
}
@media only screen and (max-width: 37.5em) {
  .heading-primary--main {
    letter-spacing: 1rem;
    font-family: 5rem;
  }
}
.heading-primary--shop {
  margin-left: 5rem;
  flex: 1;
  letter-spacing: normal;
}

.heading-primary--sub {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.75rem;
  animation: moveInRight 1s ease-out;
}
@media only screen and (max-width: 37.5em) {
  .heading-primary--sub {
    letter-spacing: 0.5rem;
  }
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(
    to right,
    rgba(215, 35, 35, 0.95),
    rgba(215, 40, 40, 0.95)
  );
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}
@media only screen and (max-width: 56.25em) {
  .heading-secondary {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .heading-secondary {
    font-size: 2.5rem;
  }
}
.heading-secondary:hover {
  transform: skewY(2deg) skewX(15deg) scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}
.heading-secondary__singleProduct {
  margin-top: 0;
}

.heading-tertiary {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.heading-tertiary__singleProduct {
  font-size: xx-large;
}
.heading-tertiary--description {
  margin-left: 0rem;
}
/* .heading-tertiary__description {

} */
.heading-category {
  font-size: x-large;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
.heading--contact-us {
  color: rgba(215, 35, 35, 0.9);
  font-weight: bolder;
  font-size: large;
}
.heading--trackPage {
  margin-top: 15rem;
}
@media only screen and (max-width: 56.25em) {
  .heading--trackPage {
    margin-top: 10rem;
  }
}
/* .heading-primary--static {
  margin-right:
} */
@media only screen and (max-width: 1024px) {
  .heading-primary--static {
    font-size: 3rem;
    letter-spacing: 2.5rem;
    margin-right: 22rem;
  }
}
@media only screen and (max-width: 480px) {
  .heading-primary--static {
    font-size: 2rem;
    letter-spacing: 0.8rem;
    margin-right: 6rem;
  }
}

.section-shop {
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 0.6),
    rgba(51, 51, 51, 0.9)
  );
}
@media only screen and (max-width: 56.25em) {
  .section-shop {
    padding: 2rem 0;
  }
}

.shop-heading {
  color: white;
  text-align: center;
  align-items: center;
  padding: 10rem;
  margin-left: auto;
  margin-bottom: 2rem;
  display: flex; /* Make it a flex container */
  justify-content: center; /* Center the children horizontally */
  flex-direction: row; /* Ensure children are in a row */
  gap: 1rem; /* Add some spacing between the Link and the span */
  flex-wrap: wrap; /* Allows wrapping in case of smaller screens */
}

.shopPage-cards {
  margin-left: 10rem;
}

.category-container {
  align-self: flex-start;
  margin-bottom: 6rem;
}

.section-card {
  justify-content: center;
  align-items: center;
  /* margin-left: 40rem; */
}
@media only screen and (max-width: 56.25em) {
  .section-card {
    margin-left: 10rem;
  }
}

.paragraph {
  font-size: 1.6rem;
}
.paragraph:not(:last-child) {
  margin-bottom: 3rem;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}
@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}
@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.15;
  overflow: hidden;
}
.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.btn,
.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  bottom: 20px;
  display: inline-block;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 37.5em) {
  .btn,
  .btn:link,
  .btn:visited {
    font-size: 1.2rem;
  }
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn:active,
.btn:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.btn--white {
  background-color: #fff;
  color: #777;
  position: absolute; /* Position the button absolutely */
  bottom: 20px; /* Adjust the distance from the bottom as needed */
}
.btn--white::after {
  background-color: #fff;
}

.btn--green {
  background-color: rgba(215, 35, 35, 0.95);
  color: #fff;
}
.btn--green::after {
  background-color: rgba(215, 35, 35, 0.95);
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn-text:link,
.btn-text:visited {
  font-size: 1.6rem;
  color: rgba(215, 35, 35, 0.8);
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.8);
  padding: 3px;
  transition: all 0.2s;
}

.btn-text:hover {
  background-color: rgba(215, 35, 35, 0.8);
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.btn-text:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0);
}
.btn--addToCart {
  margin-top: 4rem;
}
.btn--toHome {
  margin-left: auto;
  padding: 1rem 2rem; /* Adjust padding for a more appropriate size */
  font-size: 1.4rem; /* Adjust font size if necessary */
  display: inline-block;
}
.btn--category {
  position: inherit;
}
.btn--dropdown {
  position: inherit;
  font-size: small;
  padding: 1rem;
  margin: 2rem;
}
.btn--header {
  top: 7vh;
  background-color: #000;
  color: white;
}
@media only screen and (max-width: 56.25em) {
  .btn--header {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 37.5em) {
  /* 600px and below */
  .btn {
    font-size: 1.2rem; /* Smaller font size for mobile */
    padding: 0.8rem 2rem; /* Adjust padding for mobile */
    bottom: 2rem; /* Adjust position for mobile */
    border-radius: 5rem; /* Slightly smaller border-radius */
  }
}

.btn--contact-us {
  background-color: #000;
}
.btn--contact-us:hover {
  background-color: #000;
}
.btn--contact-us::after {
  background-color: #000;
}
.btn--proceed-to-checkout {
  margin-top: 2rem;
}

.checkout {
  display: flex;
  justify-content: flex-end; /* This will align items to the right */
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align items to the right */
}

.card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 72rem;
  /* golden glow on hover */
}
.card:hover {
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
}
.card__side {
  height: 52rem;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}
.card__side--front {
  background-color: #fff;
}
.card__side--back {
  transform: rotateY(180deg);
}
.card__side--back-1 {
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 1),
    rgba(51, 51, 51, 1)
  );
}
.card__side--back-2 {
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 1),
    rgba(51, 51, 51, 1)
  );
}
.card__side--back-3 {
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 1),
    rgba(51, 51, 51, 1)
  );
}
.card:hover .card__side--front {
  transform: rotateY(-180deg);
}
.card:hover .card__side--back {
  transform: rotateY(0);
}
.card__picture {
  background-size: cover;
  height: 23rem;
  background-blend-mode: screen;
  /* -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%); */
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); */
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card__picture--1 {
  background-image: url("https://res.cloudinary.com/alphamuscle/image/upload/v1725536099/LEAN_GAINER_3KG_KKP_bvjlqm.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.card__picture--2 {
  background-image: url("https://res.cloudinary.com/alphamuscle/image/upload/v1729074286/whey_plus_2kg_chocolate_1_cp3fm2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.card__picture--3 {
  background-image: url("https://res.cloudinary.com/alphamuscle/image/upload/v1725442228/hydro_iso_4lbs_MK_z7k6iw.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.card__heading {
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  position: absolute;
  top: 12rem;
  right: 2rem;
  width: 75%;
}
.card__heading-span {
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card__heading-span--1 {
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 0.9),
    rgba(51, 51, 51, 0.9)
  );
}
.card__heading-span--2 {
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 0.9),
    rgba(51, 51, 51, 0.9)
  );
}
.card__heading-span--3 {
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 0.9),
    rgba(51, 51, 51, 0.9)
  );
}
.card__details {
  padding: 3rem;
}
.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}
.card__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}
.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
.card__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 8rem;
}
.card__price-only {
  font-size: 1.4rem;
  text-transform: uppercase;
}
.card__price-value {
  font-size: 6rem;
  font-weight: 100;
}
@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .card {
    height: auto;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }
  .card__side {
    height: auto;
    position: relative;
    box-shadow: none;
  }
  .card__side--back {
    transform: rotateY(0);
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }
  .card:hover .card__side--front {
    transform: rotateY(0);
  }
  .card__details {
    padding: 1rem 3rem;
  }
  .card__cta {
    position: relative;
    top: 0%;
    left: 0;
    transform: translate(0);
    width: 100%;
    padding: 7rem 4rem 4rem 4rem;
  }
  .card__price-box {
    margin-bottom: 3rem;
  }
  .card__price-value {
    font-size: 4rem;
  }
}

.composition {
  position: relative;
}
.composition__photo {
  width: 60%;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  transition: all 0.2s;
  outline-offset: 2rem;
  margin: 6rem 2rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo {
    float: left;
    position: relative;
    width: 33.33333333%;
    box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
  }
}
.composition__photo--p1 {
  left: 11rem;
  top: -14rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p1 {
    top: -3rem;
    left: 7rem;
    transform: scale(1);
    width: 60%;
  }
}
.composition__photo--p2 {
  right: -6rem;
  /* top: 2rem; */
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p2 {
    top: -5rem;
    right: -7rem;
    transform: scale(1);
    width: 60%;
    /* z-index: 100; */
  }
}
.composition__photo--p3 {
  left: 20%;
  top: 16rem;
}
.composition__photo--p4 {
  left: -4rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p3 {
    top: -6rem;
    left: 7rem;
    transform: scale(1);
    width: 60%;
  }
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p4 {
    top: -6rem;
    left: 7rem;
    transform: scale(1);
    width: 60%;
  }
}
.composition__photo:hover {
  outline: 1.5rem solid rgba(215, 35, 35, 0.8);
  transform: scale(1.05) translateY(-0.5rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}
.composition:hover .composition__photo:not(:hover) {
  transform: scale(0.95);
}

.feature-box {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
@media only screen and (max-width: 56.25em) {
  .feature-box {
    padding: 2rem;
  }
}
.feature-box__icon {
  font-size: 6rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  background-image: linear-gradient(
    to right,
    rgba(215, 35, 35, 0.8),
    rgba(51, 51, 51, 0.8)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
@media only screen and (max-width: 56.25em) {
  .feature-box__icon {
    margin-bottom: 0;
  }
}
.feature-box:hover {
  transform: translateY(-1.5rem) scale(1.03);
}

.form {
  background-color: whitesmoke;
  padding: 2rem;
  margin: 2rem 0;
}
/* Tablet view */
@media only screen and (max-width: 75em) {
  .book__form {
    width: 80%; /* Increase width to better fit the tablet screen */
    padding: 5rem; /* Reduce padding for smaller screens */
    margin-left: 5rem;
  }
}

/* Mobile view */
@media only screen and (max-width: 56.25em) {
  .book__form {
    width: 100%;
    padding: 3rem;
    margin-left: 0;
  }

  .contact-form {
    margin: 2rem 0;
    padding: 2rem;
    width: 100%;
  }

  .form {
    margin: 2rem 0;
    padding: 2rem;
    width: 100%;
  }
}

.form__group:not(:last-child) {
  margin-bottom: 2rem;
  padding: 1rem;
}

.form__input {
  font-size: 1.5rem;
  font-family: inherit;
  color: black;
  padding: 1.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 100%;
  display: block;
  transition: all 0.3s;
}
@media only screen and (max-width: 56.25em) {
  .form__input {
    width: 100%;
  }
}
.form__input:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input::-webkit-input-placeholder {
  color: #999;
}

.form__label {
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  width: 100%;
}
@media only screen and (max-width: 56.25em) {
  .form__label {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.form__radio-group {
  width: 49%;
  display: inline-block;
}
@media only screen and (max-width: 56.25em) {
  .form__radio-group {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.form__radio-input {
  display: none;
}

.form__radio-label {
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
  padding-left: 4.5rem;
}

.form__radio-button {
  height: 3rem;
  width: 3rem;
  border: 5px solid rgba(215, 35, 35, 0.8);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.4rem;
}
.form__radio-button::after {
  content: "";
  display: block;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(215, 35, 35, 0.95);
  opacity: 0;
  transition: opacity 0.2s;
}

.form__radio-input:checked ~ .form__radio-label .form__radio-button::after {
  opacity: 1;
}

.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
@supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
  .popup {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.popup__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  background-color: #fff;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: table;
  overflow: hidden;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.25);
  transition: all 0.5s 0.2s;
}
.popup__left {
  width: 33.333333%;
  display: table-cell;
}
.popup__right {
  width: 66.6666667%;
  display: table-cell;
  vertical-align: middle;
  padding: 3rem 5rem;
}
.popup__img {
  display: block;
  width: 100%;
}
.popup__text {
  font-size: 1.4rem;
  margin-bottom: 4rem;
  -moz-column-count: 2;
  -moz-column-gap: 4rem;
  -moz-column-rule: 1px solid #eee;
  column-count: 2;
  column-gap: 4rem;
  column-rule: 1px solid #eee;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.popup:target {
  opacity: 1;
  visibility: visible;
}
.popup:target .popup__content {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.popup__close:link,
.popup__close:visited {
  color: #777;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  font-size: 3rem;
  text-decoration: none;
  display: inline-block;
  transition: all 0.2s;
  line-height: 1;
}
.popup__close:hover {
  color: #55c57a;
}

.story {
  width: 75%;
  margin: 0 auto;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  padding: 6rem;
  padding-left: 9rem;
  font-size: 1.6rem;
  transform: skewX(-12deg);
}
@media only screen and (max-width: 56.25em) {
  .story {
    width: 100%;
    padding: 4rem;
    padding-left: 7rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .story {
    transform: skewX(0);
  }
}
.story__shape {
  width: 15rem;
  height: 15rem;
  float: left;
  transform: translateX(-3rem) skewX(12deg);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
@supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
  .story__shape {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    -webkit-shape-outside: circle(50% at 50% 50%);
    shape-outside: circle(50% at 50% 50%);
    border-radius: none;
  }
}
@media only screen and (max-width: 37.5em) {
  .story__shape {
    transform: translateX(-3rem) skewX(0);
  }
}
.story__img {
  height: 100%;
  transform: translateX(-4rem) scale(1.4);
  backface-visibility: hidden;
  transition: all 0.5s;
}
.story__text {
  transform: skewX(12deg);
}
@media only screen and (max-width: 37.5em) {
  .story__text {
    transform: skewX(0);
  }
}
.story__caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 20%);
  color: #fff;
  text-transform: uppercase;
  font-size: 1.7rem;
  text-align: center;
  opacity: 0;
  transition: all 0.5s;
  backface-visibility: hidden;
}
.story:hover .story__caption {
  opacity: 1;
  transform: translate(-50%, -50%);
}
.story:hover .story__img {
  transform: translateX(-4rem) scale(1);
  filter: blur(3px) brightness(80%);
}

.footer {
  background-color: #000;
  padding: 10rem 0;
  font-size: 1.4rem;
  color: #f7f7f7;
}
@media only screen and (max-width: 56.25em) {
  .footer {
    padding: 8rem 0;
  }
}
.footer__logo-box {
  text-align: center;
  margin-bottom: 8rem;
}
@media only screen and (max-width: 56.25em) {
  .footer__logo-box {
    margin-bottom: 6rem;
  }
}
.footer__logo-img {
  width: 17rem;
  height: 14rem;
}
.footer__navigation {
  border-top: 1px solid #777;
  padding-top: 2rem;
}
@media only screen and (max-width: 56.25em) {
  .footer__navigation {
    width: 100%;
    text-align: center;
  }
}
.footer__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}

.footer__item {
  display: inline-block;
  margin-right: 2rem;
}

@media only screen and (max-width: 56.25em) {
  .footer__item {
    width: 100%;
    text-align: center;
  }
}

.footer__item:not(:last-child) {
  margin-bottom: 1.5rem;
}
.footer__link:link,
.footer__link:visited {
  color: #f7f7f7;
  background-color: #333;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.2s;
}
.footer__link:hover,
.footer__link:active {
  color: rgba(215, 35, 35);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4);
  transform: rotate(5deg) scale(1.3);
}

.footer__link-header {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  display: block;
  margin-bottom: 1rem;
}

.footer__sub-list {
  list-style: none;
  padding: 0;
}

.footer__sub-item:not(:last-child) {
  margin-bottom: 1rem;
}

.footer__copyright {
  border-top: 1px solid #777;
  padding-top: 2rem;
  width: 80%;
  float: right;
  left: 0;
}

@media only screen and (max-width: 56.25em) {
  .footer__copyright {
    width: 100%;
    float: none;
  }
}

.copyright {
  float: inline-start;
  border-top: none;
  bottom: 0;
}

.row {
  max-width: 114rem;
  margin: 0 auto;
}
.row:not(:last-child) {
  margin-bottom: 8rem;
}
@media only screen and (max-width: 56.25em) {
  .row:not(:last-child) {
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .row {
    max-width: 50rem;
    padding: 0 3rem;
  }
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
.row [class^="col-"] {
  float: left;
}
.row [class^="col-"]:not(:last-child) {
  margin-right: 6rem;
}
@media only screen and (max-width: 56.25em) {
  .row [class^="col-"]:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6rem;
  }
  .row--main-cards [class^="col-"] {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 56.25em) {
  .row [class^="col-"] {
    width: 100% !important;
  }
}
.row .col-1-of-2 {
  width: calc((100% - 6rem) / 2);
}
.row .col-1-of-3 {
  width: calc((100% - 2 * 6rem) / 3);
  margin-bottom: 2.5rem;
}
.row .col-2-of-3 {
  width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem);
}
.row .col-1-of-4 {
  width: calc((100% - 3 * 6rem) / 4);
}
.row .col-2-of-4 {
  width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem);
}
.row .col-3-of-4 {
  width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem);
}

/* .header {
  overflow: hidden; 
  height: 112vh;
  background-size: contain;
  background-position: center;
  position: relative;
  background-color: #eeeeee;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__slider {
  display: flex;
  height: 100%;
  width: 100%; 
  animation: slideImages 12s infinite; 

.header__slide {
  flex: 1 0 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes slideImages {
  0% {
    transform: translateX(0%);
  }
  33.33% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  66.66% {
    transform: translateX(-100%);
  }
  83.33% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}


.header__slide--1 {
  background-image: url(../img/1-p.png);
}

.header__slide--2 {
  background-image: url(../img/2nd-banner-p.png);
}

.header__slide--3 {
  background-image: url(../img/3-p.png);
}

@media only screen and (max-width: 56.25em) {
  .header {
    margin-bottom: 6rem;
    height: 30vh;
    background: cover;
    width: 100%;
  }

  .header__slider {
    width: 300%;
    background-size: contain; 
    background-position: center;
    background: no-repeat;
  }
}

@media only screen and (max-width: 37.5em) {
  .header {
    height: 20vh; 
  }

  .header__logo-box {
    top: 0.5rem;
    left: 0.5rem;
    transform: scale(0.7);
  }
} */

/* @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    height: 95vh;
  }
} */
/* @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
  only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
  only screen and (min-width: 125em) {
  .header {
    background-image: url(../img/hero.jpg);
  }
} */
/* @media only screen and (max-width: 37.5em) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
  }
} */
.header__logo-box {
  /* box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid transparent; */
  position: absolute;
  top: 8rem;
  left: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__logo {
  /* box-sizing: border-box;
  border-radius: 50%; */
  /* border: 1px solid transparent; */
  height: 12rem;
  width: 13rem;
}
@media only screen and (max-width: 56.25em) {
  .header__logo {
    height: 7rem;
    width: 8rem;
  }
}

.auth__logo-box {
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth__logo {
  height: 10rem;
  width: 10rem;
}

@media only screen and (max-width: 56.25em) {
  .authentication-container {
    flex-direction: column;
    width: 90%;
    max-width: none;
  }

  .auth__logo-box {
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .auth__logo {
    height: 7rem;
    width: 7rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .authpage {
    padding: 1rem;
  }

  .authentication-container {
    padding: 0;
    width: 100%;
  }

  .auth__logo-box {
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .auth__logo {
    height: 5rem;
    width: 5rem;
  }
}

.header__logo--shop {
  top: 6rem;
  left: 6rem;
}

.header__text-box {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.navigation__checkbox {
  display: none;
}

.navigation__button {
  background-color: #fff;
  height: 7rem;
  width: 7rem;
  position: fixed;
  top: 6rem;
  right: 6rem;
  border-radius: 50%;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
}
@media only screen and (max-width: 56.25em) {
  .navigation__button {
    top: 4rem;
    right: 4rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .navigation__button {
    top: 3rem;
    right: 3rem;
  }
}

.navigation__background {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 6.5rem;
  right: 6.5rem;
  background-image: radial-gradient(rgba(215, 35, 35, 1), rgba(0, 0, 0, 1));
  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
@media only screen and (max-width: 56.25em) {
  .navigation__background {
    top: 4.5rem;
    right: 4.5rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .navigation__background {
    top: 3.5rem;
    right: 3.5rem;
  }
}

/* Added a new CSS rule to handle the open state */
.navigation--open .navigation__background {
  transform: scale(80);
}

.navigation__nav {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  opacity: 0;
  width: 0;
  overflow: hidden; /* Added to prevent interaction when hidden */
  pointer-events: none; /* Added to prevent interaction when hidden */
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

/* New CSS rule to handle open navigation */
.navigation--open .navigation__nav {
  opacity: 1;
  width: 100%;
}

.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
  width: 100%;
}

.navigation__item {
  margin: 1rem;
}

.navigation__link:link,
.navigation__link:visited {
  display: inline-block;
  font-size: 3rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-image: linear-gradient(
    69deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 220%;
  transition: all 0.4s;
}
.navigation__link:link span,
.navigation__link:visited span {
  margin-right: 1.5rem;
  display: inline-block;
}

.navigation__link:hover,
.navigation__link:active {
  background-position: 100%;
  color: rgba(215, 35, 35, 0.8);
  transform: translateX(1rem);
}
.navigation__link--sign-out {
  color: whitesmoke;
  font-size: x-large;
}

.navigation__icon {
  position: relative;
  margin-top: 3.5rem;
}
.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  width: 3rem;
  height: 2px;
  background-color: #333;
  display: inline-block;
}
.navigation__icon::before,
.navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.navigation__icon::before {
  top: -0.8rem;
}
.navigation__icon::after {
  top: 0.8rem;
}

.navigation__button:hover .navigation__icon::before {
  top: -1rem;
}

.navigation__button:hover .navigation__icon::after {
  top: 1rem;
}

/* Changed to handle the dynamic class */
.navigation--open .navigation__background {
  transform: scale(80);
}

.navigation--open .navigation__nav {
  opacity: 1;
  width: 100%;
  pointer-events: auto; /* Allow interaction when open */
}

/* Changed to handle the dynamic class */
.navigation--open .navigation__icon {
  background-color: transparent;
}

.navigation--open .navigation__icon::before {
  top: 0;
  transform: rotate(135deg);
}

.navigation--open .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg);
}

.section-about {
  background-color: #f7f7f7;
  padding: 25rem 0;
  margin-top: -10vh;
}
@media only screen and (max-width: 56.25em) {
  .section-about {
    padding: 20rem 0;
  }
}
.section-about__singleProduct {
  margin: 10vh 8vh;
  padding: 10rem 2rem;
}

.section-features {
  overflow: hidden;
  height: 70vh;
  background-size: contain;
  background-position: center;
  position: relative;
  background-color: #eeeeee;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../img/asa.png);
}
.section-features > * {
  transform: skewY(7deg);
}
@media only screen and (max-width: 56.25em) {
  .section-features {
    height: 70vh;
    background-repeat: no-repeat;
  }
}

.section-tours {
  background-color: #f7f7f7;
  padding: 25rem 0 15rem 0;
  margin-top: -10rem;
}
@media only screen and (max-width: 56.25em) {
  .section-tours {
    padding: 20rem 0 10rem 0;
  }
}

.section-stories {
  position: relative;
  padding: 15rem 0;
}
@media only screen and (max-width: 56.25em) {
  .section-stories {
    padding: 10rem 0;
  }
}

.section-book {
  padding: 15rem 0;
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 0.8),
    rgba(51, 51, 51, 0.8)
  );
}
@media only screen and (max-width: 56.25em) {
  .section-book {
    padding: 10rem 0;
  }
}
.section-book__singleProduct {
  padding: 7rem 2rem;
}
.section-book__checkout {
  height: 90vh;
}

.book {
  background-image: linear-gradient(
      105deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 50%,
      transparent 50%
    ),
    url(../img/img1.jpg);
  background-size: 100%;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 75em) {
  .book {
    background-image: linear-gradient(
        105deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.9) 65%,
        transparent 65%
      ),
      url(../img/img1.jpg);
    background-size: cover;
  }
}
@media only screen and (max-width: 56.25em) {
  .book {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.9) 100%
      ),
      url(../img/img1.jpg);
  }
}
.book__form {
  width: 50%;
  padding: 0 10rem 20rem 20rem;
  margin-left: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
@media only screen and (max-width: 75em) {
  .book__form {
    width: 65%;
  }
}
@media only screen and (max-width: 56.25em) {
  .book__form {
    width: 100%;
  }
}

.cart {
  padding: 10rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cart h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.cart ul {
  list-style: none;
  padding: 0;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-item img {
  width: 100px;
  height: 100px;
  border-radius: 0.5rem;
  object-fit: cover;
}

.cart-item div {
  flex: 1;
  margin-left: 1rem;
}

.cart-item h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.cart-item p {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
}

.cart-item .price {
  font-size: 1rem;
  font-weight: 500;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.cart-item .quantity-control {
  display: flex;
  align-items: center;
}

.cart-item .quantity-control button {
  background-color: #e5e7eb;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin: 0 0.25rem;
  cursor: pointer;
}

.cart-item .quantity-control button:hover {
  background-color: #d1d5db;
}

.cart-item .quantity-control span {
  font-size: 1rem;
  font-weight: 500;
}

.cart-item .remove-btn {
  background-color: #ef4444;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cart-item .remove-btn:hover {
  background-color: #dc2626;
}

.cart-total {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-total .total-price {
  font-size: 1.5rem;
  font-weight: 700;
}

.cart-total .total-amount {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1f2937;
}

.cart-total button {
  background-color: #ef4444;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cart-total button:hover {
  background-color: #dc2626;
}

.section-checkout {
  padding: 2rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checkout__form,
.address-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.address-summary {
  margin: 4rem 20rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form__group {
  margin-bottom: 1.5rem;
}

.form__input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 1.5rem;
}

.form__label {
  margin-top: 0.5rem;
  font-size: 1.3rem;
  color: #6b7280;
  width: 100%;
  height: 100%;
}

.single-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding: 2rem;
  max-width: 1200px;
}
.single-product__price {
  text-align: left;
}
.single-product__images {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}
.single-product__image {
  object-fit: cover;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  height: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.single-product__thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.single-product__thumbnail {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  border: 1px solid #ddd;
}

.single-product__thumbnail:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.single-product__thumbnail--active {
  border: 2px solid #28a745;
  opacity: 1;
}

.image-gallery {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
}

.image-container {
  width: 100%;
  margin-bottom: 1rem;
}

.product-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  display: block;
}

.product-details {
  flex: 1;
  padding: 1rem;
  max-width: 500px;
}

.product-details h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.product-details p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.product-details .price {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.quantity-control button {
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.quantity-control span {
  margin: 0 1rem;
  font-size: 1.2rem;
}

.slider-button {
  position: absolute;
  top: 50%; /* Ensure visibility against images */
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  border: none;
  width: auto; /* Override the width from btn class */
  height: 5rem; /* Override the height from btn class */
  border-radius: 50%; /* Override the border radius from btn class */
  margin: 0; /* Ensure no extra margin */
}

.slider-button--left {
  position: absolute; /* Absolute positioning to place it correctly */
  left: 0.7rem;
}

.slider-button--right {
  position: absolute; /* Absolute positioning to place it correctly */
  right: 0.7rem;
}
.line {
  margin: 0 1rem 4rem 1rem;
  border: 1px solid grey;
  box-sizing: border-box;
  padding: 0;
}

.admin-dashboard {
  padding: 15rem 0;
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 0.8),
    rgba(51, 51, 51, 0.8)
  );
}

.admin-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.admin-links a {
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
}

.manage-products,
.manage-orders {
  padding: 2rem;
}

.add-product input,
.add-product button,
.product-item button {
  margin: 0.5rem 0;
}

.product-list,
.order-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.product-item,
.order-item {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.authpage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  gap: 2rem;
  position: relative;
}
/* .authentication-container {
  display: flex;
  width: 900px;
  justify-content: space-between;
  margin: 30px auto;
} */
.authentication-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  justify-content: space-between;
  margin: 30px auto;
  padding: 0 1rem;
}
.sign-up-container {
  display: flex;
  flex-direction: column;
  width: 380px;
}
.sign-up-container > h2 {
  margin: 10px 0;
}
.sign-up-container .button-container {
  display: flex;
  justify-content: space-between;
}

.group {
  position: relative;
  margin: 45px 0;
}
.group .form-input {
  background: none;
  background-color: white;
  color: grey;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid grey;
  margin: 2px 0;
}
.group .form-input:focus {
  outline: none;
}
.group .form-input:focus ~ .form-input-label {
  top: -14px;
  font-size: 12px;
  color: black;
}
.group input[type="password"] {
  letter-spacing: 0.3em;
}
.group .form-input-label {
  color: grey;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}
.group .form-input-label.shrink {
  top: -14px;
  font-size: 12px;
  color: black;
}

.button-container {
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: "Open Sans Condensed";
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.button-container:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.button-container.google-sign-in {
  background-color: #4285f4;
  color: white;
  padding: 0rem;
}
.button-container.google-sign-in:hover {
  background-color: #357ae8;
  border: none;
}
.button-container.inverted {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.button-container.inverted:hover {
  background-color: black;
  color: white;
  border: none;
}

@media only screen and (max-width: 56.25em) {
  .contact-us {
    width: 100%;
    margin-bottom: 2rem;
    flex: 1;
    /* overflow: hidden; */
    justify-content: center;
    align-items: flex-start;
  }
}

.contact-form {
  flex: 1;
  margin: 4rem;
  padding: 0 24rem;
  width: auto;
}

.long-description--para {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 1.7rem;
  line-height: 1.6;
  color: #333; /* Dark gray for readability */
  margin: 20px 0; /* Adds vertical spacing */
  text-align: justify; /* Aligns text for a clean block appearance */
  padding: 10px 15px; /* Adds padding for better readability */
  background-color: #f9f9f9; /* Light gray background for contrast */
  border-left: 10px solid #ffd700; /* Adds a left border for a highlight effect */
  border-radius: 5px; /* Slightly rounds the corners */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
}

/* Tablet view */
@media only screen and (max-width: 75em) {
  .book__form {
    width: 80%; /* Increase width to better fit the tablet screen */
    padding: 5rem; /* Reduce padding for smaller screens */
    margin-left: 5rem;
    flex-direction: column;
  }
}

/* Mobile view */
@media only screen and (max-width: 56.25em) {
  .book__form {
    width: 100%;
    padding: 3rem;
    margin-left: 0;
    flex-direction: column;
  }

  .contact-form {
    margin: 2rem 0;
    padding: 2rem;
    width: 100%;
  }

  .form {
    margin: 2rem 0;
    padding: 2rem;
    width: 100%;
  }
}

.form.contact-form {
  flex: 1 1 45%;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 12rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.form__group {
  margin-bottom: 1.5rem;
  padding: 2rem;
}

.form__label {
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.form__input {
  width: 100%;
  padding: 0.8rem;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333;
}

.form__input::placeholder {
  color: #666;
}

.section-book__singleProduct {
  padding: 7rem 2rem;
  background-image: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 0.8),
    rgba(51, 51, 51, 0.8)
  );
}

.section-about__singleProduct {
  margin: 10vh 8vh;
  padding: 10rem 2rem;
}

.single-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* Center align content */
  margin: 0 auto;
  padding: 2rem;
  max-width: 100%; /* Ensure it doesn't exceed screen width */
}

.single-product__images {
  position: relative;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}

.single-product__image {
  object-fit: cover;
  width: 100%;
  max-width: 350px;
  height: auto; /* Auto height for responsive scaling */
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.single-product__thumbnails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.single-product__thumbnail {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  border: 1px solid #ddd;
}

.single-product__thumbnail--active {
  border: 2px solid #28a745;
  opacity: 1;
}

.single-product__mrp {
  text-align: left;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.quantity-control button {
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.quantity-control span {
  margin: 0 1rem;
  font-size: 1.2rem;
}

.slider-button {
  top: 50%;
  color: white;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  border: none;
  width: 3rem; /* Smaller width for mobile */
  height: 3rem; /* Smaller height for mobile */
  border-radius: 50%;
}

.slider-button--left {
  left: 0.7rem; /* Adjusted for mobile */
}

.slider-button--right {
  right: 0.7rem; /* Adjusted for mobile */
}

.header__logo-box {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

@media only screen and (max-width: 56.25em) {
  .section-about__singleProduct {
    margin: 5vh 2vh;
    padding: 5rem 1rem;
  }

  .single-product {
    padding: 1rem;
  }

  .single-product__details {
    text-align: center;
  }

  .quantity-control button {
    font-size: 0.8rem;
  }

  .quantity-control span {
    font-size: 1rem;
  }

  .heading-tertiary {
    font-size: 1.4rem; /* Smaller font size */
  }
}

/*repeated css*/

.page-content {
  background: linear-gradient(
    to right bottom,
    rgba(215, 35, 35, 1),
    rgba(51, 51, 51, 1)
  );
  color: white;
  padding: 3rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading--main-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
}

.heading--main-pages .header__logo-box {
  margin-bottom: 1rem;
}

.heading--main-static {
  font-size: 2.7rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-align: center;
}
.heading--main-static__contact-us {
  margin-top: 12rem;
}

.contact-us__para {
  max-width: 900px;
  text-align: left;
  line-height: 1.8;
  font-size: 1.3rem;
  margin-bottom: 2.5rem;
  margin-top: 12rem;
  padding: 1rem;
  border-left: 3px solid #fff;
  background-color: #1714141a; /* Subtle background effect */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.heading-static {
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: 1.7rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 56.25em) {
  .heading--main-static {
    font-size: 2.3rem;
    letter-spacing: 0.08rem;
  }

  .contact-us__para {
    font-size: 1.1rem;
    padding: 0.8rem;
  }

  .heading-static {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .heading--main-static {
    font-size: 2rem;
    letter-spacing: 0.07rem;
  }

  .contact-us__para {
    font-size: 1rem;
    padding: 0.7rem;
  }

  .heading-static {
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 25em) {
  .heading--main-static {
    font-size: 1.7rem;
    letter-spacing: 0.06rem;
  }

  .contact-us__para {
    font-size: 0.9rem;
    padding: 0.6rem;
  }

  .heading-static {
    margin-bottom: 0.8rem;
    font-size: 1.1rem;
  }
}

.header__logo {
  height: 10rem;
  width: 10rem;
  top: 4rem;
  left: 4rem;
  position: absolute;
}

@media only screen and (max-width: 56.25em) {
  .header__logo {
    height: 8rem;
    width: 8rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .header__logo {
    height: 10rem;
    width: 10rem;
  }
}

@media only screen and (max-width: 25em) {
  .header__logo {
    height: 8rem;
    width: 8rem;
  }
}

.book__form--contact-us {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.form.contact-form {
  flex: 1;
  margin-right: 8rem;
}

.contact__us--paragraph {
  flex: 1;
  font-size: 1.2rem;
  line-height: 1.8;
  padding: 1rem;
  margin-top: 30rem;
  border-left: 3px solid #fff;
  background-color: rgba(255, 255, 255, 0.1); /* Subtle background effect */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

@media only screen and (max-width: 56.25em) {
  .book__form--contact-us {
    flex-direction: column;
  }

  .form.contact-form,
  .contact__us--paragraph {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .contact__us--paragraph {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .contact__us--paragraph {
    font-size: 1rem;
    padding: 0.8rem;
  }
}

@media only screen and (max-width: 25em) {
  .contact__us--paragraph {
    font-size: 0.9rem;
    padding: 0.6rem;
  }
}

.section-card .row {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop */
  gap: 2rem; /* Space between cards */
  max-width: 114rem;
  margin: 0 auto;
  padding: 0 2rem;
  justify-content: center;
}

@media only screen and (max-width: 75em) {
  .section-card .row {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for larger tablets */
    margin-left: 0;
  }
}

@media only screen and (max-width: 56.25em) {
  .section-card .row {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller tablets */
    max-width: 80rem;
    margin-left: 0;
  }
}

@media only screen and (max-width: 37.5em) {
  .section-card .row {
    grid-template-columns: 1fr; /* 1 column for mobile */
    max-width: 40rem;
    margin-left: 0;
  }
}

.card {
  perspective: 150rem;
  position: relative;
  height: 52rem;
  width: 30rem;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}

.card__side {
  height: 100%;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
}

.card__side--front {
  background-color: #fff;
}

.card__side--back {
  transform: rotateY(180deg);
}

.card:hover .card__side--front {
  transform: rotateY(-180deg);
}

.card:hover .card__side--back {
  transform: rotateY(0);
}

.card__picture {
  background-size: contain;
  height: 23rem;
  background-blend-mode: screen;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card__details {
  padding: 3rem;
}

.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}

.card__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}

.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}

.card__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 8rem;
}

.card__price-only {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.card__price-value {
  font-size: 6rem;
  font-weight: 100;
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .card {
    height: 50rem;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }

  .card__side {
    height: auto;
    position: relative;
    box-shadow: none;
  }

  .card__side--back {
    transform: rotateY(0);
  }

  .card:hover .card__side--front {
    transform: rotateY(0);
  }

  .card__details {
    padding: 1rem 3rem;
  }

  .card__cta {
    position: relative;
    top: 0%;
    left: 0;
    transform: translate(0);
    width: 100%;
    padding: 7rem 4rem 4rem 4rem;
  }

  .card__price-box {
    margin-bottom: 3rem;
  }

  .card__price-value {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 37.5em), only screen and (hover: none) {
  .card {
    height: auto;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }

  .card__side {
    height: auto;
    position: relative;
    box-shadow: none;
  }

  .card__side--back {
    transform: rotateY(0); /* Prevent back side from rotating */
  }

  .card:hover .card__side--front {
    transform: rotateY(0); /* Prevent front side from rotating */
  }

  .card__details {
    padding: 1rem 3rem;
  }

  .card__cta {
    position: relative;
    top: 0%;
    left: 0;
    transform: translate(0);
    width: 100%;
    padding: 7rem 4rem 4rem 4rem;
  }

  .card__price-box {
    margin-bottom: 3rem;
  }

  .card__price-value {
    font-size: 4rem;
  }
}

.header {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #eeeeee;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__slider {
  display: flex;
  height: 100%;
  width: 100%;
  animation: slideImages 12s infinite;
}

.header__slide {
  flex: 1 0 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes slideImages {
  0%,
  33.33% {
    transform: translateX(0%);
  }
  50%,
  66.66% {
    transform: translateX(-100%);
  }
  83.33%,
  100% {
    transform: translateX(-200%);
  }
}

.header__slide--1 {
  background-image: url(https://res.cloudinary.com/ddl3mgrmz/image/upload/v1725477781/1_p_fmr3qu.png);
}

.header__slide--2 {
  background-image: url(https://res.cloudinary.com/ddl3mgrmz/image/upload/v1725477765/2nd_banner_p_x3oquj.png);
}

.header__slide--3 {
  background-image: url(https://res.cloudinary.com/ddl3mgrmz/image/upload/v1725477774/3_p_gxssej.png);
}

@media (max-width: 480px) {
  .header {
    height: 25vh;
    width: 100vw;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .header {
    height: 40vh;
    width: 100vw;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header {
    height: 35vh;
    width: 100vw;
  }
}

@media (min-width: 1025px) {
  .header {
    height: 100vh;
    width: 100vw;
  }
}

.header__logo-box {
  position: absolute;
  top: 2rem;
  left: 2rem;
  transform: scale(0.8);
}

@media (max-width: 480px) {
  .header__logo-box {
    top: 1rem;
    left: 1rem;
    transform: scale(0.6);
  }
}

.section-features {
  overflow: hidden;
  height: 70vh;
  width: 100vw;
  background-size: contain;
  background-position: center;
  position: relative;
  background-color: #eeeeee;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../img/asa.png);
}

.section-features > * {
  transform: skewY(7deg);
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .section-features {
    height: 50vh;
    width: 100vw;
    background-size: contain;
  }
}

/* Mobile Devices */
@media (min-width: 481px) and (max-width: 768px) {
  .section-features {
    height: 60vh;
    width: 100vw;
    background-size: contain;
  }
}

/* Tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .section-features {
    height: 65vh;
    width: 100vw;
    background-size: contain;
  }
}

/* Desktop Devices */
@media (min-width: 1025px) {
  .section-features {
    height: 80vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.category-container {
  align-self: flex-start;
  margin-bottom: 6rem;
  text-align: center;
  animation: fadeIn 1s ease-out forwards;
  transform: scale(0.8);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.heading-category {
  font-size: 2rem;
  color: #ffcc00;
  text-transform: uppercase;
  animation: slideInLeft 1.2s ease-out forwards;
  letter-spacing: 0.2rem;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.btn--category {
  padding: 1rem 2rem;
  font-size: 1.6rem;
  border: 2px solid #ffcc00;
  background-color: transparent;
  color: #ffcc00;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: bounceIn 1.4s ease-out forwards;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  70% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.btn--category:hover {
  background-color: #ffcc00;
  color: #fff;
  transform: translateY(-5px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.btn--category:focus {
  outline: none;
  box-shadow: 0 0 10px #ffcc00;
}

.heading-secondary__singleProduct {
  font-size: 4rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #b72525;
  background-image: linear-gradient(to right, #d72323, #ff6161);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.3rem;
  margin-bottom: 3rem;
  text-align: center;
  transition: all 0.4s;
}
.heading-secondary--categories {
  margin-bottom: 10rem;
}

@media only screen and (max-width: 37.5em) {
  .heading-secondary--categories {
    margin-bottom: 2rem;
  }
}

.heading-secondary__singleProduct:hover {
  transform: scale(1.05) rotate(1deg);
  text-shadow: 0 0 2rem rgba(255, 97, 97, 0.5);
}

.heading-tertiary__singleProduct {
  font-size: 3rem; /* Larger font size for prominence */
  font-weight: 800; /* Bold weight for emphasis */
  text-transform: uppercase; /* Makes the text stand out */
  background: linear-gradient(
    to right,
    rgba(60, 60, 60, 1),
    rgba(90, 90, 90, 1),
    rgba(120, 120, 120, 1)
  ); /* Sleek gradient with modern grey tones */
  -webkit-background-clip: text;
  color: transparent; /* Uses the background clip trick to make text gradient */
  letter-spacing: 0.3rem; /* Slight spacing for a clean look */
  text-align: center; /* Centers the product name */
  margin-bottom: 2rem; /* Adds spacing below the title */
  position: relative;
  transition: all 0.3s ease-in-out;
}

.heading-tertiary__singleProduct::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -0.5rem;
  width: 50%;
  height: 2px;
  background-color: rgba(
    215,
    35,
    35,
    0.95
  ); /* Red underline for visual impact */
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.heading-tertiary__singleProduct:hover {
  transform: scale(1.05); /* Slightly enlarges the text on hover */
  letter-spacing: 0.4rem; /* Increases letter spacing slightly on hover */
}

.heading-tertiary__singleProduct:hover::after {
  opacity: 1;
  width: 80%; /* Expands the underline on hover */
}

.single-product__pricing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.single-product__mrp {
  font-size: 1.8rem;
  color: #777;
  text-decoration: line-through;
}

.single-product__price {
  font-size: 2rem;
  color: #d72323;
  font-weight: bold;
}

.single-product__discount {
  font-size: 1.4rem;
  color: #ff6161;
  background-color: rgba(255, 230, 230, 0.7);
  padding: 0.2rem 0.6rem;
  border-radius: 0.5rem;
  margin-left: 1rem;
}

.size-selection,
.flavour-selection {
  margin: 2rem 0;
  text-align: left;
}

.btn--dropdown {
  padding: 1.2rem;
  font-size: 1.4rem;
  border-radius: 5px;
  background-color: #d72323;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
}

.btn--dropdown:hover {
  background-color: #ff6161;
  box-shadow: 0 0 15px rgba(255, 97, 97, 0.5);
}

.btn--addToCart {
  background-color: #d72323;
  color: #fff;
  padding: 1.5rem 3rem;
  font-size: 1.8rem;
  border-radius: 10rem;
  margin-top: 2rem;
  transition: all 0.3s ease;
}

.btn--addToCart:hover {
  background-color: #ff6161;
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(255, 97, 97, 0.2);
}

.heading-tertiary--description {
  font-size: 1.8rem;
  color: #333;
  margin-top: 4rem;
}

.long-description--para {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 1.7rem;
  line-height: 1.6;
  color: #333;
  margin: 20px 0;
  text-align: justify;
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-left: 8px solid #ffd700;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.long-description--para:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  transform: translateX(10px);
}

.quantity-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  padding: 1rem;
  border-radius: 10px;
  background-color: #f7f7f7;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.quantity-button {
  background-color: rgba(215, 35, 35, 0.95);
  border: none;
  color: #fff;
  font-size: 2rem;
  padding: 1rem 2rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0 1rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.quantity-button:hover {
  background-color: rgba(215, 50, 50, 1);
  transform: scale(1.1) translateY(-3px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.quantity-button:active {
  transform: scale(0.95) translateY(1px);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.quantity-display {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin: 0 1rem;
  padding: 0.5rem 2rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  min-width: 60px;
  text-align: center;
  line-height: 1.5;
}

.heading-tertiary__description {
  font-size: 2rem; /* A slightly larger font for better readability */
  font-weight: 700; /* Stronger emphasis on the description title */
  text-transform: uppercase; /* To maintain uniformity with other headings */
  color: #3d3d3d; /* A deep grey tone for a clean, modern look */
  letter-spacing: 0.2rem; /* Adds a subtle spacing between letters */
  margin-bottom: 1.5rem; /* Creates space below the heading */
  position: relative; /* Allows for positioning effects */
  text-align: center; /* Centers the text */
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Adds a soft shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.heading-tertiary__description::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -10px; /* Above the text */
  width: 50px;
  height: 4px;
  background-color: rgba(
    215,
    35,
    35,
    0.95
  ); /* Adds a red bar above the heading */
  transform: translateX(-50%);
  transition: all 0.3s ease;
  opacity: 0.8;
}

.heading-tertiary__description::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px; /* Below the text */
  width: 60px;
  height: 2px;
  background-color: #333; /* Dark grey underline for contrast */
  transform: translateX(-50%);
  transition: all 0.3s ease;
}

.heading-tertiary__description:hover::after {
  width: 100px; /* Expands the underline on hover */
}

.heading-tertiary__description:hover {
  transform: translateY(-5px); /* Slight lifting effect on hover */
}

.single-product__images {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2rem;
}

.single-product__image {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.single-product__image:hover {
  transform: scale(1.05);
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 1rem;
  border-radius: 50%;
  transition: background-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.slider-button--left {
  left: -2rem;
}

.slider-button--right {
  right: -2rem;
}

.slider-button:hover {
  background-color: rgba(215, 35, 35, 0.95);
  color: white;
}

.single-product__preview {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.single-product__thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid transparent;
  cursor: pointer;
  transition: transform 0.2s, border-color 0.2s;
  border-radius: 5px;
}

.single-product__thumbnail:hover {
  transform: scale(1.1);
}

.single-product__thumbnail--active {
  border-color: rgba(215, 35, 35, 0.95);
}

@media only screen and (max-width: 768px) {
  .single-product__image {
    max-width: 350px;
  }

  .slider-button {
    font-size: 1.5rem;
    padding: 0.8rem;
  }

  .slider-button--left {
    left: -1.5rem;
  }

  .slider-button--right {
    right: -1.5rem;
  }

  .single-product__thumbnail {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 480px) {
  .single-product__image {
    max-width: 100%;
  }

  .slider-button {
    font-size: 1.2rem;
    padding: 0.5rem;
  }

  .slider-button--left {
    left: -1rem;
  }

  .slider-button--right {
    right: -1rem;
  }

  .single-product__thumbnail {
    width: 40px;
    height: 40px;
  }
}

.section__you-might-also-like {
  padding: 40px;
  background-color: #f7f7f7; /* light gray background */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* subtle shadow */
  margin-left: 0;
}

.section__you-might-also-like .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section__you-might-also-like .card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* subtle shadow */
}

.section__you-might-also-like .card:hover {
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.5); /* golden glow on hover */
}

.section__you-might-also-like .card img {
  width: 100%;
  height: 150px; /* adjust image height */
  object-fit: cover;
  border-radius: 10px 10px 0 0; /* rounded corners for image */
}

.section__you-might-also-like .card .card-content {
  padding: 20px;
}

.section__you-might-also-like .card .card-content h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.section__you-might-also-like .card .card-content p {
  font-size: 16px;
  color: #666;
}
.section__you-might-also-like h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 2rem;
  text-align: center;
}

/**/

/* Mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .long-description--para {
    font-size: 14px;
    line-height: 1.5;
    padding: 10px;
  }
  .section__you-might-also-like {
    padding: 10px;
  }
  .section__you-might-also-like h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

/* Tablets (min-width: 481px) and (max-width: 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .long-description--para {
    font-size: 16px;
    line-height: 1.5;
    padding: 15px;
  }
  .section__you-might-also-like {
    padding: 15px;
  }
  .section__you-might-also-like h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

/* Laptops (min-width: 769px) and (max-width: 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .long-description--para {
    font-size: 18px;
    line-height: 1.5;
    padding: 20px;
  }
  .section__you-might-also-like {
    padding: 20px;
  }
  .section__you-might-also-like h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

/* Desktops (min-width: 1025px) */
@media (min-width: 1025px) {
  .long-description--para {
    font-size: 20px;
    line-height: 1.5;
    padding: 25px;
  }
  .section__you-might-also-like {
    padding: 25px;
  }
  .section__you-might-also-like h2 {
    font-size: 24px;
    margin-bottom: 25px;
  }
}

/* cart updates */
@media (max-width: 480px) {
  .cart {
    padding: 1rem;
  }

  .cart h1 {
    font-size: 2rem;
  }

  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  .quantity-control {
    justify-content: space-between;
    width: 100%;
  }

  .cart-total {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn--proceed-to-checkout {
    width: 100%;
    text-align: center;
  }
}

/* Mobile Devices (e.g., iPhone 12, Galaxy S20) */
@media (min-width: 481px) and (max-width: 768px) {
  .cart h1 {
    font-size: 2.2rem;
  }

  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item img {
    width: 90px;
    height: 90px;
  }

  .cart-total {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn--proceed-to-checkout {
    width: 100%;
  }
}

/* Tablets (e.g., iPad, iPad Mini) */
@media (min-width: 769px) and (max-width: 1024px) {
  .cart h1 {
    font-size: 2.5rem;
  }

  .cart-item img {
    width: 120px;
    height: 120px;
    object-fit: contain;
  }

  .quantity-control button {
    font-size: 1.4rem;
  }

  .cart-total {
    padding: 2.5rem;
  }
}

/* Desktop Devices (e.g., Laptops, Large Monitors) */
@media (min-width: 1025px) {
  .cart {
    padding: 3rem;
  }

  .cart-item img {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }

  .cart-total {
    padding: 3rem;
  }

  .btn--proceed-to-checkout {
    padding: 1.5rem 3rem;
    font-size: 1.8rem;
  }
}

/* Modal styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 40px;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .slick-slide {
    margin-left: 5rem;
  }
}

.slick-prev:before,
.slick-next:before {
  color: black; /* Adjust button color */
}
.slide-prev {
  left: -67px;
  background-color: black;
  color: black;
}
.slide-next {
  background-color: black;
  color: black;
}

/* Slider button styles */
.slider-btn {
  background-color: red; /* Change button color to red */
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%; /* Optional: to make the buttons circular */
}

.slider-btn:hover {
  background-color: darkred; /* Darker shade when hovered */
}

.slider-btn:disabled {
  background-color: grey;
  cursor: not-allowed;
}
.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
  background: black;
  color: #0000;
  outline: none;
}

/* Checkout Summary Container */
.checkout-summary {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 10rem;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 0 20px #d72323;
}

.checkout-summary h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
  color: #333;
}

/* Cart Items */
.checkout-summary ul {
  list-style-type: none;
  padding: 0;
}

.checkout-summary ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

.checkout-summary ul li img {
  max-width: 80px;
  border-radius: 8px;
}

.checkout-summary ul li div {
  flex-grow: 1;
  margin-left: 15px;
}

.checkout-summary ul li h2 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.checkout-summary ul li p {
  margin: 5px 0;
  font-size: 1rem;
  color: #666;
}

/* Subtotal */
.subtotal {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.subtotal h2 {
  margin: 0;
}

/* Coupon Section */
.coupon-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.coupon-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.coupon-input:focus {
  border-color: #28a745;
  outline: none;
}

.coupon-section button {
  padding: 10px 20px;
  background-color: #d72323;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.coupon-section button:hover {
  background-color: #d72323;
}

/* Price Summary */
.price-summary {
  margin-top: 30px;
  font-size: 1.1rem;
  color: #333;
}

.price-summary p {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

/* Proceed to Pay Button */
.btn--proceed-to-pay {
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  background-color: #d72323;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
  text-align: center;
}

.btn--proceed-to-pay:hover {
  background-color: #d72323;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .checkout-summary {
    padding: 15px;
  }

  .coupon-section {
    flex-direction: column;
  }

  .coupon-input {
    margin-bottom: 15px;
  }

  .btn--proceed-to-pay {
    padding: 12px;
    font-size: 1rem;
  }

  .checkout-summary ul li {
    flex-direction: column;
    text-align: center;
  }

  .checkout-summary ul li img {
    margin-bottom: 10px;
  }
}

/* Main Authentication Page */
.authpage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
  background: radial-gradient(circle at 50%, #2e3a47, #141e30);
  font-family: "Poppins", sans-serif;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

/* Cool Animated Background */
.authpage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.05),
    rgba(0, 0, 0, 0.05)
  );
  animation: floatingGradient 10s ease-in-out infinite alternate;
  transform: translate(-50%, -50%);
  z-index: -1;
}

@keyframes floatingGradient {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.auth__logo-box {
  position: absolute;
  top: 20px;
  left: 20px;
}

.auth__logo {
  width: 120px;
  transition: transform 0.3s ease-in-out;
}

.auth__logo:hover {
  transform: scale(1.1);
}

/* Authentication Form Container */
.authentication-container {
  background: rgba(255, 255, 255, 0.15);
  padding: 40px 60px;
  border-radius: 15px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.3);
  max-width: 500px;
  width: 100%;
  text-align: center;
  color: white;
  position: relative;
  z-index: 1;
}

h2 {
  font-size: 2.5rem;
  background: linear-gradient(90deg, #ff512f, #dd2476);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  animation: glowText 2s infinite ease-in-out;
}

@keyframes glowText {
  0%,
  100% {
    text-shadow: 0px 2px 8px rgba(255, 75, 43, 0.7);
  }
  50% {
    text-shadow: 0px 2px 12px rgba(255, 75, 43, 1);
  }
}

span {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 30px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

/* Glowing Input Fields */
input[type="email"],
input[type="password"],
input[type="text"] {
  padding: 15px 20px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  outline: none;
  transition: background 0.5s, box-shadow 0.3s;
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1);
}

input[type="email"]:focus,
input[type="password"]:focus,
input[type="text"]:focus {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
  color: #3d3d3d;
}

/* Fantastic Button Styles */
button {
  padding: 15px;
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: linear-gradient(135deg, #ff512f, #dd2476);
  color: white;
  transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
  box-shadow: 0px 5px 20px rgba(255, 50, 70, 0.6);
  position: relative;
  overflow: hidden;
}

button:hover {
  background: linear-gradient(135deg, #ff512f, #f09819);
  transform: translateY(-5px);
  box-shadow: 0px 10px 25px rgba(255, 50, 70, 1);
}

button:active {
  transform: translateY(0px);
}

button[type="button"].google {
  background: #4285f4;
  color: white;
  box-shadow: 0px 5px 20px rgba(66, 133, 244, 0.5);
}

button[type="button"].google:hover {
  background: #357ae8;
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(66, 133, 244, 0.7);
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

/* Responsive Media Queries */
@media (max-width: 1024px) {
  .authentication-container {
    padding: 30px 50px;
  }

  h2 {
    font-size: 2.2rem;
  }

  button {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .authentication-container {
    padding: 25px 40px;
    max-width: 400px;
  }

  h2 {
    font-size: 1.8rem;
  }

  button {
    padding: 12px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .authentication-container {
    padding: 20px;
    max-width: 320px;
  }

  .auth__logo {
    width: 80px;
  }

  h2 {
    font-size: 1.6rem;
  }

  button {
    padding: 10px;
    font-size: 0.9rem;
  }

  input[type="email"],
  input[type="password"],
  input[type="text"] {
    padding: 10px;
    font-size: 0.9rem;
  }
}

.auth__logo-box {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.auth__logo {
  max-width: 120px;
  height: auto;
}

/* Form containers */
.authentication-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.sign-in-container,
.sign-up-container {
  padding: 20px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  position: relative;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

span {
  font-size: 14px;
  color: #ddd;
  display: block;
  margin-bottom: 20px;
}

/* Input styling */
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input {
  padding: 10px;
  font-size: 16px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  outline: none;
  transition: border 0.3s ease;
}

input:focus {
  border-color: #4ac1f5;
}

/* Button styling */
button {
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

button[type="submit"] {
  background: linear-gradient(45deg, #ff8c00, #ffa500);
  color: white;
}

button.google {
  background: #4285f4;
  color: white;
}

button:hover {
  transform: scale(1.05);
}

.buttons-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

button:focus {
  outline: none;
}

/* Media queries for responsiveness */
@media (max-width: 480px) {
  .authentication-container {
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }

  input {
    font-size: 14px;
    padding: 8px;
  }

  button {
    font-size: 14px;
    padding: 10px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .authentication-container {
    padding: 20px;
  }

  h2 {
    font-size: 22px;
  }

  input {
    font-size: 15px;
    padding: 10px;
  }

  button {
    font-size: 15px;
    padding: 11px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .authentication-container {
    max-width: 500px;
  }

  h2 {
    font-size: 24px;
  }

  input {
    font-size: 16px;
    padding: 10px;
  }

  button {
    font-size: 16px;
    padding: 12px;
  }
}

@media (min-width: 1024px) {
  .authentication-container {
    max-width: 600px;
  }

  h2 {
    font-size: 26px;
  }

  input {
    font-size: 18px;
    padding: 12px;
  }

  button {
    font-size: 18px;
    padding: 14px;
  }
}

/* Logo styling */
.auth__logo-box {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.auth__logo {
  max-width: 120px;
  height: auto;
}

/* Form containers */
.authentication-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.sign-in-container,
.sign-up-container {
  padding: 20px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  position: relative;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

span {
  font-size: 14px;
  color: #ddd;
  display: block;
  margin-bottom: 20px;
}

/* Input styling */
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input {
  padding: 8px;
  font-size: 14px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  outline: none;
  transition: border 0.3s ease;
}

input:focus {
  border-color: #4ac1f5;
}

/* Button styling */
button {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

button[type="submit"] {
  background: linear-gradient(45deg, #ff8c00, #ffa500);
  color: white;
}

button.google {
  background: #4285f4;
  color: white;
}

button:hover {
  transform: scale(1.05);
}

.buttons-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

button:focus {
  outline: none;
}

/* Media queries for responsiveness */
@media (max-width: 480px) {
  .authentication-container {
    padding: 10px;
  }

  h2 {
    font-size: 18px;
  }

  input {
    font-size: 12px;
    padding: 6px;
  }

  button {
    font-size: 12px;
    padding: 8px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .authentication-container {
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }

  input {
    font-size: 13px;
    padding: 7px;
  }

  button {
    font-size: 13px;
    padding: 9px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .authentication-container {
    max-width: 500px;
  }

  h2 {
    font-size: 22px;
  }

  input {
    font-size: 14px;
    padding: 8px;
  }

  button {
    font-size: 14px;
    padding: 10px;
  }
}

@media (min-width: 1024px) {
  .authentication-container {
    max-width: 600px;
  }

  h2 {
    font-size: 24px;
  }

  input {
    font-size: 16px;
    padding: 10px;
  }

  button {
    font-size: 16px;
    padding: 12px;
  }
}

/* Responsive Layout */

.authpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  background: linear-gradient(
    135deg,
    rgba(58, 89, 191, 0.8),
    rgba(223, 58, 219, 0.8)
  );
}

.auth__logo-box {
  margin-bottom: 2rem;
}

.authentication-container {
  background: rgba(0, 0, 0, 0.8);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

/* Sign In/Up Form */
.sign-in-container,
.sign-up-container {
  margin-bottom: 2rem;
}

h2 {
  color: #ff5252;
  font-size: 1.6rem;
  text-align: center;
}

span {
  color: #ffffff;
  font-size: 0.9rem;
  text-align: center;
  display: block;
  margin-bottom: 1.5rem;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  background-color: #ffffff;
  border: none;
  padding: 10px;
  margin-bottom: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 90, 90, 0.5);
}

.buttons-container {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

button[type="submit"] {
  background-color: #ff9800;
  color: white;
}

button[type="button"] {
  background-color: #e91e63;
  color: white;
}

button:hover {
  transform: scale(1.05);
}

/* Responsiveness for different screen sizes */

/* Devices smaller than 480px */
@media (max-width: 480px) {
  .authentication-container {
    padding: 1rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  span {
    font-size: 0.8rem;
  }

  input {
    padding: 0.7rem;
    font-size: 0.9rem;
  }

  button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .buttons-container {
    flex-direction: column;
    gap: 0.5rem;
  }
}

/* Between 480px and 768px */
@media (min-width: 480px) and (max-width: 768px) {
  .authentication-container {
    padding: 1.5rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  span {
    font-size: 0.85rem;
  }

  input {
    padding: 0.8rem;
    font-size: 1rem;
  }

  button {
    padding: 0.55rem 1.1rem;
    font-size: 0.95rem;
  }
}

/* Between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .authentication-container {
    padding: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  span {
    font-size: 0.9rem;
  }

  input {
    padding: 0.9rem;
    font-size: 1.1rem;
  }

  button {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
  }
}

/* Greater than 1024px */
@media (min-width: 1024px) {
  .authentication-container {
    padding: 2rem;
    max-width: 500px;
  }

  h2 {
    font-size: 1.8rem;
  }

  span {
    font-size: 1rem;
  }

  input {
    padding: 1rem;
    font-size: 1.2rem;
  }

  button {
    padding: 0.7rem 1.3rem;
    font-size: 1.1rem;
  }
}

.sign-up-container {
  background: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  max-width: 400px;
  margin: auto;
  color: white;
}

.sign-up-container h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: rgba(255, 0, 100, 0.8);
}

.sign-up-container span {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.sign-up-container form {
  display: flex;
  flex-direction: column;
}

.sign-up-container form input {
  padding: 12px;
  margin-bottom: 15px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.sign-up-container form input:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(255, 50, 50, 0.8);
}

button[type="submit"] {
  background: linear-gradient(
    90deg,
    rgba(255, 123, 0, 0.9),
    rgba(255, 0, 100, 0.9)
  );
  border: none;
  padding: 15px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.3s ease;
  margin-bottom: 10px;
}

button[type="submit"]:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 0, 100, 0.9),
    rgba(255, 123, 0, 0.9)
  );
  transform: scale(1.05);
}

@media (max-width: 480px) {
  .sign-up-container {
    padding: 20px;
    max-width: 90%;
  }

  .sign-up-container h2 {
    font-size: 22px;
  }

  .sign-up-container form input {
    font-size: 16px;
  }

  button[type="submit"] {
    padding: 2px;
    font-size: 16px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .sign-up-container {
    padding: 25px;
    max-width: 90%;
  }

  .sign-up-container h2 {
    font-size: 24px;
  }

  .sign-up-container form input {
    font-size: 16px;
  }

  button[type="submit"] {
    padding: 6px;
    font-size: 17px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sign-up-container {
    padding: 30px;
    max-width: 400px;
  }

  .sign-up-container h2 {
    font-size: 26px;
  }

  .sign-up-container form input {
    font-size: 17px;
  }

  button[type="submit"] {
    padding: 15px;
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .sign-up-container {
    max-width: 450px;
  }

  .sign-up-container h2 {
    font-size: 28px;
  }

  .sign-up-container form input {
    font-size: 18px;
  }

  button[type="submit"] {
    padding: 2px;
    font-size: 19px;
  }
}

/* Styles for the COD checkbox container */
.cod-section {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

/* Styles for the COD checkbox itself */
.cod-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
}

.cod-checkbox input[type="checkbox"] {
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #28a745; /* Green color matching your theme */
  border-radius: 5px;
  position: relative;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.cod-checkbox input[type="checkbox"]:checked {
  background-color: #28a745;
  border-color: #28a745;
}

.cod-checkbox input[type="checkbox"]:checked::before {
  content: "✔";
  color: white;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  top: -4px;
  left: 4px;
}

.cod-checkbox input[type="checkbox"]:hover {
  border-color: #218838;
  box-shadow: 0 0 5px rgba(40, 167, 69, 0.4);
}

/* Label styling to match the theme */
.cod-label {
  font-weight: 500;
  color: #333;
  user-select: none;
}

/* Optional: You can add a subtle animation */
.cod-checkbox input[type="checkbox"]:checked {
  transform: scale(1.1);
}

.coupon-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background: linear-gradient(
    135deg,
    rgba(63, 94, 251, 0.6),
    rgba(252, 70, 107, 0.6)
  );
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
  animation: fadeIn 0.5s ease-in-out;
}

.coupon-input {
  width: 2%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.coupon-input:focus {
  outline: none;
  transform: scale(1.05);
  box-shadow: 0px 3px 15px rgba(255, 255, 255, 0.3);
}

.btn--coupon {
  padding: 12px 25px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 100, 0.9),
    rgba(255, 123, 0, 0.9)
  );
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0px 4px 10px rgba(0, 255, 123, 0.4);
  transition: background 0.3s ease, transform 0.3s ease;
  width: 2%;
}

.btn--coupon:hover {
  background: linear-gradient(
    135deg,
    rgba(0, 255, 160, 0.9),
    rgba(0, 200, 100, 0.9)
  );
  transform: scale(1.05);
  box-shadow: 0px 5px 20px rgba(0, 255, 160, 0.6);
}

@media screen and (max-width: 1024px) {
  .coupon-section {
    flex-direction: column;
    padding: 15px;
  }

  .coupon-input {
    width: 80%;
    font-size: 0.9rem;
  }

  .btn--coupon {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  .coupon-input {
    width: 90%;
  }

  .btn--coupon {
    padding: 8px 18px;
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 480px) {
  .coupon-section {
    padding: 10px;
  }

  .coupon-input {
    width: 95%;
    padding: 10px;
    font-size: 0.8rem;
  }

  .btn--coupon {
    padding: 7px 16px;
    font-size: 0.8rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cod-toggle_label {
  font-size: 2rem;
  color: grey;
}
